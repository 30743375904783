<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Aprovações"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="aprovacoesLista()"
              >
                <span class="text-nowrap">Pesquisar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="aprovacoes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Sem resultados"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'aprovacoes-hit-editar', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(statusAprovacao)="data">
          <b-badge
            pill
            :variant="`light-${statusAprovacaoVariant(data.item.statusAprovacao)}`"
            class="text-capitalize"
          >
            {{ statusAprovacaoLabel(data.item.statusAprovacao) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'aprovacoes-hit-editar', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAprovacoes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'
import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from './UserListAddNew.vue'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {

      aprovacoes: [],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'nomeComposicao', label: 'Composição', sortable: true },
        { key: 'dataCadastro', label: 'Inclusao', sortable: true },
        { key: 'statusAprovacao', label: 'Status', sortable: true },
        { key: 'actions', label: '' },
      ],
      perPage: 25,
      currentPage: 1,
      totalAprovacoes: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,

    }
  },
  created() {
    this.aprovacoesLista()
  },
  methods: {

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO') return 'danger'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO') return 'Reprovado'
      return 'Em análise'
    },

    // CARREGAR APROVAÇÕES

    aprovacoesLista() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      // console.log( paginaAtual );

      useJwt.hitAprovacoesLista({
        page: paginaAtual,
        size: this.perPage,
        sort: 'DESC',
      })
        .then(response => {
          this.aprovacoes = response.data.content
          this.totalAprovacoes = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

    // MUDAR PÁGINA

    mudarPagina() {
      this.aprovacoesLista()
    },

    // MUDAR PÁGINA

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
